export const codeRouter = `
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
`

export const codeRouterExport = `
export default router
`

export const codeSettingRoutes = `
routes: [
 {
   path: '/',
   name: 'home',
   component: () => import('@/views/Home/Home.vue')
 },
 {
   path: '/error-404',
   name: 'error-404',
   component: () => import('@/views/error/Error404'),
   meta: {
       layout: 'full',
   }
 },
 {
   path: '*',
   redirect: 'error-404',
 }
]
`
export const codeResponsiveStoreIndex =`
import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
    },
    strict: process.env.DEV,
})

`

export const codeResponsiveMainJs=`
import store from './store'

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')

`
export const codeResponsiveStoreApp =`
export default {
    namespaced: true,
    state: {
        isWeb:0,
        isMobile: false
    },
    getters: {
        isWeb: state => {
            return state.isWeb;
        },
        isMobile: state => {
            return state.isMobile;
        }
    },
    mutations: {
        UPDATE_IS_WEB(state, val) {
            state.isWeb = val
        },
        UPDATE_IS_MOBILE(state, val) {
            state.isMobile = val;
        }
    }
}

`

export const codeResponsiveApp =`
<script>
 created() {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  },
    methods: {
    handleWindowResize() {
      this.$store.commit('app/UPDATE_IS_WEB', window.innerWidth)
      if (window.innerWidth <= 768) {
        this.$store.commit('app/UPDATE_IS_MOBILE', true)
        return;
      }
      this.$store.commit('app/UPDATE_IS_MOBILE', false)
    }
  }
</script>

`

export const codeResponsiveHeader =`
<template>
 <div class="site-header" v-if="!$store.state.app.isMobile">
 </div>
 <div class="mobile-site-header" v-else>
 </div>
</template>
`

export const codeResponsiveHeaderAll =`
<template>
  <div class="site-header" v-if="!$store.state.app.isMobile">
    <div class="responsive-width">
      <div class="header-logo-menu">
        <div class="header-logo" @click="go('/')"><img src="@/assets/images/logo.png"/></div>
        <div class="header-menu">
          <ul>
            <li @click="go('/')">Home</li>
            <li @click="selectMenu()">
              <div class="blockchain-dropdown">
                <button ref="btn">Blockchain</button>
                <i class="fas fa-caret-down"></i>
              </div>
              <div v-show="active" class="drowdown">
                <div class="header-dropdown">
                  <span @click="go('/txns')">View Txns</span>
                  <span @click="go('/blocks')">View Blocks</span>
                </div>
              </div>
            </li>
            <li @click="go('/tokens')">Tokens</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="mobile-site-header" v-else>
    <div class="header-logo-menu">
      <div class="header-logo" @click="go('/')"><img src="@/assets/images/logo.png"/></div>
      <b-button v-b-toggle.sidebar-right variant="outline-secondary" class="mobile-header-menu">
        <b-icon icon="list" class="list-btn"></b-icon>
      </b-button>
      <b-sidebar id="sidebar-right" class="sidebar" title="MENU" bg-variant="white" style="width: 200px" right shadow>
        <div class="px-3 py-2 menu-select" @click="go('/')">
          Home
        </div>
        <div class="blockchain-menu">
          <b-navbar toggleable>
            <b-navbar-brand>Blockchain</b-navbar-brand>
            <b-navbar-toggle target="navbar-toggle-collapse">
              <template #default="{ expanded }">
                <b-icon v-if="expanded" icon="chevron-right"></b-icon>
                <b-icon v-else icon="chevron-down"></b-icon>
              </template>
            </b-navbar-toggle>
            <b-collapse id="navbar-toggle-collapse" is-nav>
              <b-navbar-nav class="ml-auto" style="font-size:18px; border:unset">
                <b-nav-item class="mobile-nav" @click="go('/txns')">View Transfers</b-nav-item>
                <b-nav-item class="mobile-nav" @click="go('/blocks')">View Blocks</b-nav-item>
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
        </div>
        <div class="px-3 py-2" @click="go('/tokens')">
          Tokens
        </div>
      </b-sidebar>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      isShow: false,
      menu: [
        {
          header: true,
          title: 'MENU',
          hiddenOnCollapse: true,
          icon: 'fa fa-x'
        },
        {
          href: '/',
          title: 'Home'
        },
        {
          title: 'Blockchain',
          child: [
            {
              href: '/txns',
              title: 'View Txns',
            },
            {
              href: '/blocks',
              title: 'View Blocks',
            }
          ]
        },
        {
          href: '/tokens',
          title: 'Tokens'
        }
      ],
      active: false,
    }
  },
  created() {
    window.addEventListener('click', this.onClick)
  },

  methods: {
    go(path) {
      this.$router.push({path: path})
    },
    selectMenu() {
      this.active = !this.active
    },
    onClick(e) {
      if (e.target !== this.$refs.btn) {
        this.active = false
      }
    }
  }
}
</script>

`

export const codePagingStart =`
let offset = (page - 1) * this.perPage;

"start": offset,

`

export const codePagingLimit = `
data() {
 return {
  perPage: 10
 }
}

"limit": this.perPage,

`
export const codePagingType = `
<template>
 <select name="token_category" class="select" v-model="selected">
  <option value="Album">Album</option>
  <option disabled value="Artist">Artist</option>
 </select>
</template>

data() {
 return {
  selected: 'Album'
 }
}

"searchType": this.selected,

`

export const codePagingInput = `
<template>
 <input type="search" id="site-search" placeholder="Search" class="search-tokens" aria-label="search" v-model="inputValue" @keyup.enter="search">
</template>

data() {
 return {
  inputValue: ''
 }
}

"searchText": this.inputValue

`

export const codeGetPagingToken = `
<template>
   <div class="search-box">
     <input type="search" id="site-search" placeholder="Search" class="search-tokens" aria-label="search" v-model="inputValue" @keyup.enter="search">
       <button type="submit" class="submit-tokens" @click="search">
         <b-icon icon="search"/>
       </button>
   </div>
</template>

<script>
data() {
    return {
      inputValue: '',
      selected: 'Album',
      totalCnt: 0
    }
  },
  methods: {
      pagination() {
      this.$http.post('https://node.beatsomeone.com/music/total_cnt', {
        "searchType": this.selected,
        "searchText": this.inputValue
      })
          .then(res => {
            this.totalCnt = res.data.total_count
          }).catch((err) => {
        console.log(err)
      })
    },
      }
  }
</script>

`

export const codePagingAll =`
<template>
  <div class="site-body home-height">
    <div class="list">
      <div class="list-container">
        <div class="select-box">
          <select name="token_category" class="select" v-model="selected">
            <option value="Album">Album</option>
            <option disabled value="Artist">Artist</option>
          </select>
          <div class="search-box">
            <input type="search" id="site-search" placeholder="Search" class="search-tokens"
                   aria-label="search" v-model="inputValue" @keyup.enter="search">
            <button type="submit" class="submit-tokens" @click="search">
              <b-icon icon="search"/>
            </button>
          </div>
        </div>
        <div class="table-center">
          <div class="table-inside">
            <div>
              <b-table class="token-list-table" :items="tokenList" id="my-table" @row-clicked="goToken">
              </b-table>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <b-pagination aria-controls="my-table" v-model="currentPage" :per-page="perPage" :total-rows="totalCnt"
                        first-number
                        last-number align="right"></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TokenList",
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      tokenList: [],
      inputValue: '',
      selected: 'Album',
      totalCnt: 0,
      start: 0,
    }
  },
  watch: {
    currentPage() {
      this.tokens(this.currentPage)
    }
  },
  async mounted() {
    await this.clickPage(this.currentPage)
  },
  methods: {
    clickPage(page) {
      this.tokens(page);
    },
    tokens(page) {
      let temp = [];
      this.pagination()
      let offset = (page - 1) * this.perPage;
      this.$http.post('https://node.beatsomeone.com/music/list', {
        "start": offset,
        "limit": this.perPage,
        "searchType": this.selected,
        "searchText": this.inputValue
      }).then(res => {
        res.data.forEach(album => {
          temp.push({
            Image: album.cit_file_1,
            Album: album.cit_name,
            TxHash: album.extrincs,
            Id: album.cit_id
          })
          this.tokenList = temp
        })
      }).catch((err) => {
        console.log(err)
      })
    },
    pagination() {
      this.$http.post('https://node.beatsomeone.com/music/total_cnt', {
        "searchType": this.selected,
        "searchText": this.inputValue
      })
          .then(res => {
            this.totalCnt = res.data.total_count
          }).catch((err) => {
        console.log(err)
      })
    },
    goToken(tokenList) {
      this.$router.push({
        name: "tokendetails",
        params: {token_id: tokenList.Id}
      })
    },
    search() {
      this.tokens(1)
    }
  }
}
</script>

`
export const codeHowToPagination =`
<template>
  <div>
    <b-table id="my-table" :items="items"></b-table>
    
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
   ></b-pagination>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        rows: 100,
        perPage: 10,
        currentPage: 1
      }
    }
  }
</script>

`

export const codeTransition = `
<template>
<transition-group tag="div">
  <div v-for="(block, b) in mainBlock" :key="block.block_num" class="latest-block-table" :data-index="b">
    <div class="block-and-include">
     <div class="block-num">
       <div class="block-num-title">
          Block#
       </div>
       <div class="block-num-content" @click="goBlockInfo(b)">
          {{ block.block_num | numParsing }}
       </div>
      </div>
      <div class="block-include" style="border-top-color:transparent">
        <div class="block-include-title">
          Includes
        </div>
        <div class="extrinsic-event">
          <div class="block-include-extrinsic">
            {{ block.extrinsics_count }} Extrinsic
          </div>
          <div class="block-include-event">
            {{ block.event_count }} Event
          </div>
        </div>
      </div>
    </div>
    <div class="block-time">
      <timeago :datetime="block.block_timestamp | timestamp " :auto-update="1"
               :converterOptions="{ includeSeconds: true }"
               :converter="convertTime"></timeago>
      <i class="far" :class="block.finalized ? 'fa-check-circle' : 'fa-clock'"/>
    </div>
  </div>
</transition-group>
</template>

`

export const codeTransitionCss=`
.v-enter {
    transform: translateY(-20px);
}

.v-enter-active {
    transition: transform 2s;
}

`
export const codeImportAxios = `
import Vue from 'vue'

import axios from 'axios'

`
export const codeAxios=`
const axiosIns = axios.create({
headers: {
      'Content-Type': 'application/json',
    }
});
    
export default axiosIns
    `
export const codeAxiosMainJs = `
import axiosIns from './libs/axios'

Vue.use(axiosIns)

`

export const codePrototype = `
Vue.prototype.$http = axiosIns;

`

export const codePrototypeHttp = `
async latestBlocks() {
      await this.$http.post('/api/scan/blocks', {
        "row": 25,
        "page": 0
      }).then(res => {
        this.mainBlock = res.data.data.blocks
      }).catch((err) => {
        console.log(err)
      })
    }
    
`

export const codeUsingAxios = `
axios.method('/url', {
    data
  })
  .then(function (response) {
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });
  
`

export const codeUsingAxiosViaInstance = `
//main.js

import axiosIns from './libs/axios'

Vue.use(axiosIns)


//libs/axios.js

import Vue from 'vue'

import axios from 'axios'

const axiosIns = axios.create({
    headers: {
        'Content-Type': 'application/json',
    }
});

Vue.prototype.$http = axiosIns;

export default axiosIns


//views/Home.vue

async latestBlocks() {
  await this.$http.post('/api/scan/blocks', {
    "row": 25,
    "page": 0
  }).then(res => {
    this.mainBlock = res.data.data.blocks
  }).catch((err) => {
    console.log(err)
  })
}
    
`

export const codeMixins = `
import axiosIns from "../libs/axios";

const baseUrl = '/api/scan'
const tokensUrl = 'https://node.beatsomeone.com/music'

export async function blocks() {
    return axiosIns.post(baseUrl + '/blocks', {
        "row" : 25,
        "page" : 0
    })
}

export async function tokens() {
    return axiosIns.post(tokensUrl + '/list'{
        "start": offset,
        "limit": this.perPage,
        "searchType": this.selected,
        "searchText": this.inputValue
    })
}

export {axiosIns}

`

export const codeImportMixins = `
<script>
import * as mixinData from '../mixins/index'

methods: {
  mainBlocks() {
    mixinData.blocks().then(res => {
      this.mainBlock = res.data.data.blocks;
    }).catch((err) => {
      console.log(err)
    })
  }
}
</script>

`

export const codeUsingAxiosViaMixins = `
//libs/axios

import axios from 'axios'

const axiosIns = axios.create({
    headers: {
        'Content-Type': 'application/json',
    }
});

export default axiosIns


//mixins/index.js

import axiosIns from "../libs/axios";

const baseUrl = '/api/scan'
const tokensUrl = 'https://node.beatsomeone.com/music'

export async function blocks() {
    return axiosIns.post(baseUrl + '/blocks', {
        "row" : 25,
        "page" : 0
    })
}

export async function extrinsics() {
    return axiosIns.post(baseUrl + '/extrinsics', {
        "row" : 25,
        "page" : 0
    })
}

export async function tokens() {
    return axiosIns.post(tokensUrl + '/list', {
        "start": offset,
        "limit": this.perPage,
        "searchType": this.selected,
        "searchText": this.inputValue})
}

export {axiosIns}


//views/Home.vue

<script>
import * as mixinData from '../mixins/index'

data() {
    return {
        mainBlock: [],
        mainExtrinsics: [],
        mainToken: []
    }
},
mounted() {
  this.mainBlocks()  
  this.mainExtrinsics()
  this.mainTokens()
},

methods: {
  mainBlocks() {
    mixinData.blocks().then(res => {
      this.mainBlock = res.data.data.blocks;
    }).catch((err) => {
      console.log(err)
    })
  },
  mainExtrinsics() {
    mixinData.extrinsics().then(res => {
      this.mainExtrinsics = res.data.data;
    }).catch((err) => {
      console.log(err)
    })
  },
  mainTokens(){
    mixinData.tokens().then(res => {
      this.mainToken = res.data
   }).catch((err) => {
      console.log(err)
    })
  }
}
</script>
`
export const codeUsingAxiosDirect = `
<script>
import axios from 'axios'

export default {
  data() {
    return {
      mainBlocks: []
    }
  },
  mounted() {
    this.latestBlocks()
  },
  methods: {
    latestBlocks()) {
      axios.post('/api/scan/block', {
        "row": 25,
        "page": 0
      }).then (res=>{
        this.mainBlock = res.data.data.blocks
      }).catch((err)=>{
          console.log(err)
      })
    }
  }
}
</script>

`

export const codeUsingAxiosSetInterval = `
<script>
  data() {
    return {
      mainBlock: [],
      latestBlocksInterval: null,
    }
  },
  async mounted() {
    await this.latestBlocks()
    this.latestBlocksInterval = setInterval(this.latestBlocks, 7000)
  },
  methods: {
  async latestBlocks() {
    await this.$http.post('/api/scan/blocks', {
      "row": 25,
      "page": 0
    }).then(res => {
      this.mainBlock = res.data.data.blocks
    }).catch((err) => {
      console.log(err)
    })
   }
  }
</script>

`

export const codeUsingAxiosClearInterval =`
<script>
  async mounted() {
    await this.latestBlocks()
    this.latestBlocksInterval = setInterval(this.latestBlocks, 7000)
  },
  beforeDestroy() {
    clearInterval(this.latestBlocksInterval);
  }
</script>

`

export const codeFullLayout = `
<template>
  <div class="layout-full">
    <router-view/>
  </div>
</template>
`

export const codeMainLayout = `
<template>
  <div class="layout-main">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from '@/layouts/components/Header.vue'
import Footer from '@/layouts/components/Footer.vue'

export default {
  components: {
    Header,
    Footer,
  },
}
</script>
`

export const codeHeader = `
<template>
  <header class="header" :class="{'mobile-bg':!isShow}">
    <div v-if="!$store.state.app.isMobile"
         class="web-header" >
      <div class="nav-logo">
        <router-link
            to="/"
            tag="img"
            :src="require(\`@/assets/images/logo-white.png\`)"/>
      </div>
      <div class="menu">
        <component
            :is="resolveNavComponent(item)"
            v-for="(item, itemKey) in navMenuItems"
            :key="'headerMenu' + itemKey"
            :item="item"
        />
      </div>
    </div>
    <div class="mobile-header" v-else>
      <div class="mobile-logo">
        <router-link
            v-show="isShow"
            to="/"
            tag="img"
            :src="require(\`@/assets/images/logo-white.png\`)"
        />
      </div>
      <i
          v-show="isShow"
          class="fas fa-bars mobile-navbar-toggle"
         @click="changeIsShow"></i>
    </div>
    <div v-show="!isShow">
      <div class="mobile-menu">
        <component
            :is="resolveNavComponent(item)"
            v-for="(item, itemKey) in navMenuItems"
            :key="'headerMenu' + itemKey"
            :item="item"
            @click.native="changeIsShow"
        />
      </div>
      <img
          ref="close"
          src="@/assets/images/icon-close.png" alt="close"
          class="close"
          @click="changeIsShow()" >
    </div>
  </header>
</template>
<script>
import navMenuItems from '@/navigation'
import NavMenuHeaderLink from './NavMenuHeaderLink.vue'

export default {
  components: {
    NavMenuHeaderLink,
  },
  data() {
    return {
      isShow: true,
    }
  },
  setup() {
    const resolveNavComponent = item => {
      console.log(item)
      return 'nav-menu-header-link'
    }
    console.log(navMenuItems)

    return {
      navMenuItems,
      resolveNavComponent,
    }
  },
  methods: {
    changeIsShow() {
      this.isShow = !this.isShow
    },
  }
}
</script>
`

export const codeFooter = `
<template>
  <footer class="footer">
    <img
      src="@/assets/images/logo-white.png"
      alt="logo-white"
    />
    <div class="copyright">
      ©{{ year }} by enterBlock Capital. Proudly created with enterBlock
    </div>
  </footer>
</template>

<script>
export default {
  components: {},
  computed: {
    year() {
      return (new Date()).getFullYear()
    },
  },
}
</script>
`

export const codeRouterpushList=`
<template>
 <b-table class="block-list-table" :items="blockInfo" id="my-table" @row-clicked="goBlock">
  <template #cell(Block_Hash)="data">
   <div>{{ data.value | hashParsing }}</div>
  </template>       
 </b-table>
</template>

<script>
data() {
    return {
      mainBlock: [],
    }
  },
  
  async mounted() {
  await this.latestBlocks()
  this.latestBlocksInterval = setInterval(this.latestBlocks, 7000)
  },
  
 methods: {
    async latestBlocks() {
      await this.$http.post('/api/scan/blocks', {
        "row": 25,
        "page": 0
      }).then(res => {
        this.mainBlock = res.data.data.blocks
      }).catch((err) => {
        console.log(err)
      })
    },
    
  goBlockInfo(b) {
      this.$router.push({
        name: "blockdetails",
        params: {block_num: this.mainBlock[b].block_num}
      })
    }
   }
</script>
`

export const codeRouterpushDetails = `
<script>
 async getBlock() {
      await this.$http.post('/api/scan/block', {
        "block_num": Number(this.$route.params.block_num)
      }).then(res => {
        this.block = res.data.data;
        this.success = this.block.finalized ? 'Finalized' : 'Unfinalized'
        this.timestamp =  new Date(res.data.data.block_timestamp*1000);
        this.blockTime = dayjs(this.timestamp).format('YYYY-MM-DD HH:mm:ss:a');
        this.test = dayjs(this.timestamp).toNow();
      }).catch((err) => {
        this.isNoData = true
        console.log(err)
      })
    }
</script>

`
export const codeRouterpushRouter=`
routes: [
 {
   path: '/block/:block_num?',
   name: 'blockdetails',
   component: () => import('@/views/BlockDetails.vue')
 }
]

`
export const codeMediaQuery = `
/* home.scss */
.home-msg {
  margin-bottom: 60px;
  color: $whiteFont;
  font-weight: 600;
  font-size: 50px;
  letter-spacing: $letterSpacing;
}

@media screen and (max-width: 1024px) {
  .home-msg {
    color: $whiteFont;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: $letterSpacing;

    span:nth-child(1)::after {
      content: "\\a";
      white-space: pre;
    }
  }
}
`

export const textFolderStructure = `
enterBlock-vx.x/
│
├── docs/                                   -> documentation
├── public/
│   ├── favicon.ico
│   ├── logo400.png
│   ├── loader.css                          -> Initial Loader CSS
│   └── index.html                          -> Main HTML
│
├── src/
│   │ 
│   ├── @core/
│   │    ├── components/                    -> SCSS, Feather & Loader CSS
│   │    │    └── feather-icon/             -> Feather Font Icons
│   │    └── utils/                         -> filter.js, utils.js
│   │                  
│   ├── assets/
│   │    ├── images/                        -> Images used
│   │    └── scss/                          -> Template SCSS files, check folder structure below
│   │
│   ├── components/                         -> enterBlock custom components
│   │    ├── Button/                        
│   │    ├── Card/                        
│   │    └── Form/                         
│   │   
│   ├── config/                             -> telegram token, channerId
│   │
│   ├── layouts/            
│   │    │
│   │    ├── components/                    -> Layout components
│   │    │    ├── Footer/                   -> Footer component
│   │    │    ├── Header/                   -> Header component
│   │    │    ├── NavMenuHeaderGroup/       -> Navbar Components for HeaderGroup
│   │    │    └── NavMenuHeaderLink/        -> Navbar Components for HeaderLink
│   │    │
│   │    ├── full/                          -> Full page layout
│   │    └── main/                          -> Main layout
│   │
│   ├── libs/                               -> Collection of various libraries
│   ├── navigation/                         -> navigation menu
│   ├── router.js                           -> router file
│   ├── store/                              -> Vuex Store
│   ├── views/                              -> View files for all pages
│   ├── App.vue                             -> Application main vue file
│   ├── globalComponents.js                 -> Gloablly registered components
│   └── main.js                             -> Application main js file
│ 
│
├── .browserlistrc                          -> Browser support
├── .env                                    -> telegram environmental variables
├── .eslintrc.js                            -> ESLint Configuration
├── .gitignore                              -> gitignore
├── .prettierignore                         -> prettierignore  
├── babel.config.js                         -> Babel configuration
├── jsconfig.json                           -> Directory indicates
├── package.json                            -> Package json
├── postcss.config.js                       -> postcss configuration
├── README.md                               -> Readme
└── vue.config.js                           -> VueJS configuration
`

