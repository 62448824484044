<template>
  <div class="e-content">
    <div id="Transition">
      <h1>Transition Component</h1>
      <p>Home 페이지에서 데이터가 새롭게 들어오는 것을 시각적으로 보여주기 위해 Vue의 Transition 기능을 사용했습니다.
      </p>

      <b-card title="트랜지션 컴포넌트란?">
        <b-card-text> 📚 참고:
          <b-link
              href="https://kr.vuejs.org/v2/guide/transitions.html"
              target="_blank"
          >
            Vue Transition
          </b-link>
        </b-card-text>
        <b-card-text>Vue는 CSS의 Transition 효과를 더 빠르고 간단하게 구현할 수 있도록 항목이 DOM에 삽입, 갱신 또는 제거 될 때 Transition 컴포넌트 (태그)를 제공합니다.</b-card-text>
        <b-card-text>Vue의 라이프사이클과 동시에 동작할 수 있어 transition을 사용할 때 별도의 타이머 함수가 필요하지 않습니다.</b-card-text>
        <b-card-text>Transition 컴포넌트가 아닌 transition 하위 태그 요소에 효과가 나타납니다.</b-card-text>
        <b-card-text>class name은 <code>transition name="ex"</code>로 지정 시 css에서 <code>.ex-enter</code> 형태로 사용할 수 있고, class name을 따로 지정하지 않을 시 <code>.v-enter</code>형태로 사용할 수 있습니다.</b-card-text>
        <br/>
        <b-card-text>트랜지션에는 여섯 가지 클래스가 적용됩니다.</b-card-text>
        <b-card-text>
          <ul class="e-list">
            <li>v-enter: 엘리먼트가 삽입되기 전에 적용되고 한 프레임 후에 제거됩니다.</li>
            <li>v-enter-active: 엘리먼트가 삽입되기 전에 적용됩니다. 트랜지션/애니메이션이 완료되면 제거됩니다.</li>
            <li>v-enter-to: 엘리먼트가 삽입된 후 (동시에 v-enter가 제거됨), 트랜지션/애니메이션이 끝나면 제거되는 하나의 프레임을 추가했습니다.</li>
            <li>v-leave: 진출 트랜지션이 트리거될 때 적용되고 한 프레임 후에 제거됩니다.</li>
            <li>v-leave-active: 진출 트랜지션이 트리거되면 적용되고 트랜지션/애니메이션이 완료되면 제거됩니다.</li>
            <li>v-leave-to: 진출 트랜지션이 트리거되고 (동시에 v-leave가 제거됨), 트랜지션/애니메이션이 끝나면 제거되는 하나의 프레임을 추가했습니다.</li>
          </ul>
        </b-card-text>
      </b-card>

      <b-card>
        <b-card-text>Beat Someone Scan의 새로운 블록은 7초마다 렌더링되도록 설정되어있습니다. 렌더링 될 때마다 transition 효과가 적용되도록 CSS Transition이 아닌 Vue의 Transition Component를 사용합니다.</b-card-text>
        <b-card-text>v-for 항목 전체가 렌더링되야 하므로 v-for 항목 상위 태그 요소에<code>transition-group</code>을 사용합니다.</b-card-text>
        <b-card-text><code>transition-group tag="div"</code>와 같이 tag 속성을 사용하면 렌더링 된 요소를 변경할 수 있습니다. 여기서는 <code>div</code>가 새로 렌더링됩니다.</b-card-text>
        <b-card-text>Vue에서 제공되는 여섯 가지 클래스 중 v-enter와 v-enter-active를 사용하여 현재 위치에서 Y축의 -20px만큼 2초 동안 이동합니다.</b-card-text>
        <h5>📁 views/Home.vue</h5>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code class="language-javascript">{{ codeTransition }}</code></pre>
        </div>
        <h5>📁 css/custom.css</h5>
        <div class="card-code">
          <pre class="px-2 d-flex align-items-center"><code class="language-javascript">{{ codeTransitionCss }}</code></pre>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {BCard, BCardText, BImg, BButton, BCollapse, BLink} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import {codeTransition, codeTransitionCss} from './code'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'


export default {
  components: {
    BCardCode,
    BCard,
    BCardText,
    BImg,
    BButton,
    BCollapse,
    BLink,
    Prism,
  },
  data() {
    return {
      rightNavMenu: ['Transition'],
      visible: false,
      codeTransition,
      codeTransitionCss
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_RIGHT_NAV_MENU', this.rightNavMenu)

  },
}
</script>

